import React from 'react';

import { EnquireUrl } from '@constants/app';

export default ({ headline, subtitle, assets }) => {
  const banner = assets.filter(asset => asset.title === 'tradeupBanner')[0];
  const tradeUpLogo = assets.filter(asset => asset.title === 'logoTradeUp')[0];

  return (
    <section className="w-100" style={{ backgroundImage: `url(${banner.file.url})`, backgroundSize: 'cover' }}>
      <div className="container pb-5 pt-5">
        <div className="row m-5">
          <div className="col-lg-6">
            <img
              src={tradeUpLogo.file.url}
              alt="tradeup logo"
            />
            <h1 className="h3 font-weight-bold">{headline}</h1>
            <h2 className="h6 text-dark pt-3">{subtitle}</h2>
            <div className="d-flex mt-4">
              <button
                type="button"
                className="btn btn-sm btn-outline-dark bg-white mr-4"
                style={{
                  opacity: '0.5'
                }}
              >See All Plans</button>
              <a
                href={EnquireUrl}
                className="btn btn-sm btn-orange"
              >Enquire</a>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

