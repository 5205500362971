import React from 'react';

import './RangeSlider.scss';

export default ({ value, onChange }) => {
  return (
    <div className="range-slider">
      <input 
        className="range-slider__range" 
        type="range" 
        value={value.toString()}
        min="1" 
        max="1000"
        onChange={(e) => onChange(Number(e.target.value))}
      />
    </div>
  );
}
