import React, { useState } from 'react';

import { RangeSlider } from '@components/RangeSlider';
import { SignupUrl } from '@constants/app';

const formatNumber = (number) => {
  return (Math.round(number * 10) / 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default () => {

  const [ employeeCount, setEmployeeCount ] = useState(10);

  const timesheetsHours = 0.1 * employeeCount;
  const timesheetsMoney = timesheetsHours * 40;
  const timesheetsTotal = timesheetsMoney * 52;

  const productivityHours = 1.2 * employeeCount;
  const productivityMoney = productivityHours * 65;
  const productivityTotal = productivityMoney * 52;

  const siteDiaryHours = 0.25 * employeeCount;
  const siteDiaryMoney = siteDiaryHours * 65;
  const siteDiaryTotal = siteDiaryMoney * 52;

  const claimsHours = 0.025 * employeeCount;
  const claimsMoney = claimsHours * 60;
  const claimsTotal = claimsMoney * 52;

  const efficiencyGains = timesheetsTotal + productivityTotal + siteDiaryTotal + claimsTotal;

  const tradeUpCost = employeeCount >= 10 ? (12 * 10 * employeeCount) : 0;

  const overallGain = efficiencyGains - tradeUpCost;

  return (
    <section style={{ background: '#EFEFEF' }}>
      <div className="container" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
        <div className="card card-body border-0 p-5">
          <div className="row">
            <div className="col-12">
              <div className="flex-center flex-column">
                <h2>You can save up to: <span className="text-orange">${formatNumber(overallGain)}</span></h2>
                <p className="m-0">Use the slider to see how much you can save using Trade-Up</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="py-5">
                <RangeSlider
                  value={employeeCount}
                  onChange={setEmployeeCount}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <table className="table border-bottom">
                <tbody>
                  <tr>
                    <td>Timesheets</td>
                    <td>{formatNumber(timesheetsHours)} Hours saved /week</td>
                    <td>${formatNumber(timesheetsMoney)} saved /week</td>
                    <td>${formatNumber(timesheetsTotal)} saved yearly</td>
                  </tr>
                  <tr>
                    <td>Productivity</td>
                    <td>{formatNumber(productivityHours)} Hours saved /week</td>
                    <td>${formatNumber(productivityMoney)} saved /week</td>
                    <td>${formatNumber(productivityTotal)} saved yearly</td>
                  </tr>
                  <tr>
                    <td>Site Diary</td>
                    <td>{formatNumber(siteDiaryHours)} Hours saved /week</td>
                    <td>${formatNumber(siteDiaryMoney)} saved /week</td>
                    <td>${formatNumber(siteDiaryTotal)} saved yearly</td>
                  </tr>
                  <tr>
                    <td>Claims</td>
                    <td>{formatNumber(claimsHours)} Hours saved /week</td>
                    <td>${formatNumber(claimsMoney)} saved /week</td>
                    <td>${formatNumber(claimsTotal)} saved yearly</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="flex-center">
                <p>Subscription cost: ${formatNumber(tradeUpCost)} for {employeeCount} employees.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="flex-center py-4">

                <a
                  href={SignupUrl}
                  className="btn btn-outline-dark mr-4"
                >
                  Sign Up Now
                </a>

                <a
                  href={SignupUrl}
                  className="btn btn-primary"
                >
                  Try It For Free
                </a>
                 

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
